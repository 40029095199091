import React, { useContext, useEffect, useState } from "react";
import MapContext from "../../Contexts/MapContext.js";
import WmsService from "../../Shared/wms-service";
import GiswaterService from "../../Shared/Giswater/giswater-service";
import WKTFormatter from "../../Shared/wktformatter";
import RichLogger from "../../Shared/richlogger";

import Layers from "../Layers";

const InfoFromCoordinates = (props) => {
  const logger = new RichLogger("components/InfoFromCoordinates");
  const { clickedCoordinates, map, doInfo, activeLayer, srid } =
    useContext(MapContext);
  const {
    sendMessageToParent,
    vectorLayerRef,
    token,
    name,
    project_id,
    api_uri,
  } = props;

  useEffect(() => {}, []);

  useEffect(() => {
    if (!map) return;
  }, [map]);

  useEffect(() => {}, [clickedCoordinates]);

  useEffect(() => {
    if (!doInfo) return;
    const { info, hitTolerance } = doInfo;
    let { layer } = doInfo;

    if (isNaN(parseInt(hitTolerance))) hitTolerance = 5;
    logger.log("infoFromCoordinates", {
      info: info,
      layer: layer,
      hitTolerance: hitTolerance,
    });
    if (clickedCoordinates === null) {
      sendMessageToParent({
        type: "error",
        error: "No clicked coordinates",
      });
      return;
    }

    if (info.toLowerCase() === "wms") {
      logger.log("wmsInfo");
      if (layer === null || layer === "") {
        logger.log(
          "infoFromCoordinates no layer requested, use activeLayer",
          activeLayer
        );
        if (activeLayer === null) {
          sendMessageToParent({
            type: "error",
            error: "No active layer",
          });
          return;
        }
        layer = activeLayer;
      }
      //Math.min(Math.max(viewResolution*sensibilityFactor,1),1) TODO sensibility factor
      WmsService.wmsInfo({
        map: map,
        layers: Layers.getLayerList(),
        coordinates: clickedCoordinates,
        activeLayer: layer,
        token: token,
        name: name,
        project_id: project_id,
      })
        .then((msg) => {
          logger.success("wmsInfo", msg.data);
          sendMessageToParent({
            type: "info",
            infoType: info.toLowerCase(),
            data: msg.data,
          });
        })
        .catch((e) => {
          logger.error("WMSInfo", e);
        });
    } else if (info.toLowerCase() === "giswater") {
      const parameters = {
        api_uri: api_uri,
        token: token,
        project_id: project_id,
        active_layer: layer,
        visible_layers: Layers.getLayerList(),
        srid: srid,
        zoomlevel: map.getView().getZoom(),
        device: 3,
      };

      GiswaterService.infoFromCoordinates(
        parameters,
        clickedCoordinates[0],
        clickedCoordinates[1]
      )
        .then((result) => {
          logger.success("infoFromCoordinates giswater", result);
          sendMessageToParent({
            type: "info",
            infoType: info.toLowerCase(),
            data: result.message,
          });
        })
        .catch((e) => {
          logger.error("infoFromCoordinates giswater", e);
        });
    } else if (info.toLowerCase() === "geojson") {
      logger.log("geojson info");
      map.forEachFeatureAtPixel(
        map.getPixelFromCoordinate(clickedCoordinates),
        function (feature, _layer) {
          logger.success("infoFromCoordinates geojson", feature);
          sendMessageToParent({
            type: "info",
            infoType: info.toLowerCase(),
            geom_astext: WKTFormatter.GeomToString(feature.getGeometry()),
            data: JSON.stringify(feature.getProperties()),
          });
        },
        {
          layerFilter: function (_layer) {
            return _layer.get("name") === layer;
          },
          hitTolerance: parseInt(hitTolerance),
        }
      );
    }
  }, [doInfo]);

  return <React.Fragment></React.Fragment>;
};
export default InfoFromCoordinates;
