import { useContext, useEffect } from "react";
import MapContext from "../../../Contexts/MapContext.js";
import OLTileLayer from "ol/layer/Tile";
import RichLogger from "../../../Shared/richlogger";
const logger = new RichLogger("components/TileLayer");
const TileLayer = ({ source, zIndex = 0, name = null }) => {
  const { map } = useContext(MapContext);

  useEffect(() => {
    if (!map) return;
    logger.log("loaded");
    let tileLayer = new OLTileLayer({
      source,
      zIndex,
      name,
    });
    map.addLayer(tileLayer);
    tileLayer.setZIndex(zIndex);
    return () => {
      if (map) {
        map.removeLayer(tileLayer);
      }
    };
  }, [map]);
  return null;
};
export default TileLayer;
