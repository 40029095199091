import React, { useContext, useEffect, useState } from "react";
import TileLayer from "../Layers/TileLayer";
import { WMTS } from "ol/source";
import { optionsFromCapabilities } from "ol/source/WMTS";
import MapContext from "../../Contexts/MapContext.js";
import RichLogger from "../../Shared/richlogger";
import ApiService from "../../Shared/api-service";
import WmsService from "../../Shared/wms-service";

const logger = new RichLogger("components/TiledBackground");
const TiledBackground = (props) => {
  const { map } = useContext(MapContext);
  const [render, setRender] = useState(false);
  const [source, setSource] = useState(null);

  useEffect(() => {
    if (!map) return;
    if (!render) {
      logger.log("TiledBackground loaded", props);

      if (props.use_tiled_background) {
        ApiService.getTiled({
          selected_tiled: props.selected_tiled,
          token: props.token,
          api_uri: props.api_uri,
        })
          .then((result) => {
            logger.success("getTiled", result);
            getTiled({
              selected_tiled: props.selected_tiled,
              token: props.token,
              api_uri: props.api_uri,
              project_id: props.project_id,
              tiled: result[0],
            });
          })
          .catch((e) => {
            logger.error("getTiled", e);
          });
      }
    }
  }, [map, props, render]);

  const getTiled = (props) => {
    WmsService.getWMTSCustombg({
      api_uri: props.api_uri,
      token: props.token,
      project_id: props.project_id,
      name: props.tiled.id,
    })
      .then((msg) => {
        logger.success("TiledBackground getWMTSCustombg", {
          capabilities: msg,
          props: props,
        });
        let options = optionsFromCapabilities(msg, {
          layer: props.tiled.tiled_layer_name,
          matrixSet: props.tiled.matrixset,
          crossOrigin: "anonymous",
        });

        options.urls[0] = `${props.api_uri}custombgwtms?`;
        //overwrite tile url
        const sou = new WMTS(options);

        sou.tileLoadFunction = (tile, src) => {
          var client = new XMLHttpRequest();

          client.open(
            "GET",
            `${src}&project_id=${props.project_id}&name=${props.selected_tiled}`
          );
          client.responseType = "arraybuffer";
          client.setRequestHeader("Authorization", `Bearer ${props.token}`);
          client.onload = function () {
            const arrayBufferView = new Uint8Array(this.response);
            const blob = new Blob([arrayBufferView], { type: "image/png" });
            const urlCreator = window.URL;
            const imageUrl = urlCreator.createObjectURL(blob);
            tile.getImage().src = imageUrl;
          };
          client.send();
        };
        setSource(sou);
        setRender(true);
      })
      .catch((e) => {
        logger.error("TiledBackground getWMTSCustombg", e);
      });
  };

  return (
    <React.Fragment>
      {render && (
        <TileLayer source={source} zIndex={0} name="tiled"></TileLayer>
      )}
    </React.Fragment>
  );
};
export default TiledBackground;
