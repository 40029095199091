import React, { useContext, useEffect, useState } from "react";
import TileLayer from "../Layers/TileLayer";
import { osm, xyz } from "../Source";
import { TileWMS, WMTS } from "ol/source";
import { optionsFromCapabilities } from "ol/source/WMTS";
import { Attribution } from "ol/control";
import MapContext from "../../Contexts/MapContext.js";
import RichLogger from "../../Shared/richlogger";
import ApiService from "../../Shared/api-service";
import WmsService from "../../Shared/wms-service";

const logger = new RichLogger("components/Backgrounds");
const Backgrounds = (props) => {
  const { map, srid } = useContext(MapContext);
  const [render, setRender] = useState(false);
  const [source, setSource] = useState(null);
  const [bg] = useState(props.bg);
  const [api_uri] = useState(props.api_uri);
  const [token] = useState(props.token);

  useEffect(() => {
    if (!map) return;
    if (!render) {
      logger.log("Backgrounds loaded", props);
      getBackground(bg, api_uri)
        .then((msg) => {
          setSource(msg);
          setRender(true);
        })
        .catch((e) => {});
    }
  }, [map, render, bg, api_uri]);

  const getBackground = (bg) => {
    return new Promise((resolve, reject) => {
      switch (bg) {
        case "OSM":
          resolve(osm({}));
          break;
        case "CartoDBLight":
          resolve(
            xyz({
              url: "https://cartodb-basemaps-b.global.ssl.fastly.net/light_all/{z}/{x}/{y}.png",
              crossOrigin: "Anonymous",
            })
          );
          break;
        case "CartoDBDark":
          resolve(
            xyz({
              url: "http://{1-4}.basemaps.cartocdn.com/dark_all/{z}/{x}/{y}.png",
              crossOrigin: "Anonymous",
            })
          );
          break;
        case "google":
          resolve(
            xyz({
              url: "http://mt0.google.com/vt/lyrs=m&hl=en&x={x}&y={y}&z={z}",
              attributions: [
                new Attribution({ html: "© Google" }),
                new Attribution({
                  html: '<a href="https://developers.google.com/maps/terms">Terms of Use.</a>',
                }),
              ],
            })
          );
          break;
        default:
          ApiService.getBackground(props, bg)
            .then((result) => {
              logger.success("getBackground", result);
              if (bg.substring(0, 7) === "custom_") {
                getCustomBg(props, bg, result)
                  .then((wmtsResponse) => {
                    resolve(wmtsResponse);
                  })
                  .catch((e) => {
                    console.error("customBg", e);
                    reject(e);
                  });
              } else {
                setDynamicBg(bg, result)
                  .then((response) => {
                    logger.success("setDynamicBg", response);
                    resolve(response);
                  })
                  .catch((error) => {
                    console.error("setDynamicBg", error);
                    reject(error);
                  });
              }
            })
            .catch((e) => {
              logger.error("getBackground", e);
              reject(e);
            });
          break;
      }
    });
  };

  const setDynamicBg = (mapName, properties) => {
    logger.info(`setDynamicBg ${mapName}`, properties);
    return new Promise((resolve, reject) => {
      if (properties.type === "WMS") {
        const opt = JSON.parse(properties.options);
        opt.url = `${properties.service_uri}?srs=${srid}`;
        opt.params.crossOrigin = "anonymous";
        logger.info(`setDynamicBg ${mapName} WMS options`, opt);
        resolve(new TileWMS(opt));
      } else if (properties.type === "WMTS") {
        WmsService.getWMTSCapabilities({
          api_uri: properties.service_uri,
        })
          .then((msg) => {
            logger.success("setDynamicBg getWMTSCapabilities", msg);
            let options = optionsFromCapabilities(msg, {
              layer: properties.layer,
              matrixSet: properties.matrixset,
              crossOrigin: "anonymous",
            });
            options.urls[0] = properties.service_uri;
            resolve(new WMTS(options));
          })
          .catch((e) => {
            logger.error("setDynamicBg getWMTSCapabilities", e);
          });
      } else if (properties.type === "PROXY") {
        WmsService.ProxyWFSrequest(
          api_uri,
          token,
          `${properties.service_uri}&service=WMTS&VERSION=1.0.0&REQUEST=GetCapabilities`
        )
          .then((msg) => {
            logger.success("setDynamicBg ProxyWFSrequest", msg);
            let parser = WmsService.parseCapabilieties(msg).then(
              (capabilities) => {
                console.log(capabilities);
                //  resolve(parser.read(response.data));
                let options = optionsFromCapabilities(capabilities, {
                  layer: properties.layer,
                  matrixSet: properties.matrixset,
                  crossOrigin: "anonymous",
                });
                options.urls[0] = `${api_uri}proxygeoserver?uri=${properties.service_uri}?`;

                //overwrite tile url
                const sou = new WMTS(options);
                sou.tileLoadFunction = (tile, src) => {
                  var client = new XMLHttpRequest();

                  client.open("GET", `${src}`);
                  client.responseType = "arraybuffer";
                  client.setRequestHeader(
                    "Authorization",
                    `Bearer ${props.token}`
                  );
                  client.onload = function () {
                    const arrayBufferView = new Uint8Array(this.response);
                    const blob = new Blob([arrayBufferView], {
                      type: "image/png",
                    });
                    const urlCreator = window.URL;
                    const imageUrl = urlCreator.createObjectURL(blob);
                    tile.getImage().src = imageUrl;
                    //tile.getImage().src = `${src}&project_id=${props.project_id}&token=${props.token}&name=${props.name}`;
                  };
                  client.send();
                };

                resolve(sou);
              }
            );
          })
          .catch((e) => {
            logger.error("setDynamicBg ProxyWFSrequest", e);
          });
      } else {
        reject(new Error("undefined type"));
      }
    });
  };

  const getCustomBg = (props, mapName, properties) => {
    logger.info(`getCustomBg ${mapName}`, properties);
    return new Promise((resolve, reject) => {
      WmsService.getWMTSCustombg({
        api_uri: props.api_uri,
        token: props.token,
        project_id: props.project_id,
        name: props.name,
      })
        .then((msg) => {
          logger.success("getCustomBg getWMTSCapabilities", msg);
          let options = optionsFromCapabilities(msg, {
            layer: properties.layer,
            matrixSet: properties.matrixset,
            crossOrigin: "anonymous",
          });
          options.urls[0] = `${props.api_uri}custombgwtms`;
          //overwrite tile url
          const sou = new WMTS(options);
          sou.tileLoadFunction = (tile, src) => {
            var client = new XMLHttpRequest();

            client.open(
              "GET",
              `${src}&project_id=${props.project_id}&name=${props.name}`
            );
            client.responseType = "arraybuffer";
            client.setRequestHeader("Authorization", `Bearer ${props.token}`);
            client.onload = function () {
              const arrayBufferView = new Uint8Array(this.response);
              const blob = new Blob([arrayBufferView], { type: "image/png" });
              const urlCreator = window.URL;
              const imageUrl = urlCreator.createObjectURL(blob);
              tile.getImage().src = imageUrl;
              //tile.getImage().src = `${src}&project_id=${props.project_id}&token=${props.token}&name=${props.name}`;
            };
            client.send();
          };

          resolve(sou);
        })
        .catch((e) => {
          logger.error("getCustomBg getWMTSCapabilities", e);
        });
    });
  };

  return (
    <React.Fragment>
      {render && (
        <TileLayer source={source} zIndex={0} name="background"></TileLayer>
      )}
    </React.Fragment>
  );
};
export default Backgrounds;
