import { INCREMENT_ZOOM, SET_ZOOM, DECREMENT_ZOOM } from "../actionTypes";
const initialState = {
  level: 14,
};

const zoomReducer = (state = initialState, action) => {
  switch (action.type) {
    case INCREMENT_ZOOM: {
      const { level } = state;
      if (level >= 25) return state;
      return {
        ...state,
        level: level + 1,
      };
    }
    case DECREMENT_ZOOM: {
      const { level } = state;
      if (level <= 2) return state;
      return {
        ...state,
        level: level - 1,
      };
    }
    case SET_ZOOM: {
      return {
        ...state,
        level: action.payload.level,
      };
    }
    default:
      return state;
  }
};
export default zoomReducer;
