import React, { useContext, useEffect } from "react";
import MapContext from "../../../Contexts/MapContext.js";
//OL
import Geolocation from "ol/Geolocation";
import { containsCoordinate } from "ol/extent";
import Feature from "ol/Feature";
import Point from "ol/geom/Point";
import { Style, Circle, Fill, Stroke } from "ol/style/";
import RichLogger from "../../../Shared/richlogger";
let geolocation = null;
let positionFeature = new Feature();
positionFeature.setStyle(
  new Style({
    image: new Circle({
      radius: 6,
      fill: new Fill({
        color: "#3399CC",
      }),
      stroke: new Stroke({
        color: "#fff",
        width: 2,
      }),
    }),
  })
);
positionFeature.setId("positionFeature");

const logger = new RichLogger("components/Tools/Geolocalize");
const Geolocalize = (props) => {
  const { map, setGeolocation } = useContext(MapContext);
  //const { positionMarker, setPositionMarker } = useState(false);

  //*****************************************************************/
  //*******************           EFFECTS          ******************/
  //*****************************************************************/
  useEffect(() => {
    logger.log("geoLocalize start");
    geolLocalize();
    return () => {
      destroyGeolocalize();
    };
  }, []);

  useEffect(() => {
    if (!map) return;
    return () => {};
  }, [map]);
  //*****************************************************************/
  //*******************        END EFFECTS         ******************/
  //*****************************************************************/

  const destroyGeolocalize = () => {
    logger.log("destroyGeolocalize()");
    geolocation = null;
    map.getLayers().forEach((layer) => {
      if (layer.get("name") && layer.get("name") === "draw") {
        layer
          .getSource()
          .removeFeature(layer.getSource().getFeatureById("positionFeature"));
        return;
      }
    });
  };

  const geolLocalize = () => {
    if (geolocation) return;
    map.getLayers().forEach((layer) => {
      if (layer.get("name") && layer.get("name") === "draw") {
        layer.getSource().addFeature(positionFeature);
      }
    });
    geolocation = new Geolocation({
      trackingOptions: {
        enableHighAccuracy: true,
      },
      // take the projection to use from the map's view
      projection: map.getView().getProjection(),
    });
    // listen to changes in position
    geolocation.on("change:position", function () {
      logger.log("change:position");
      let coordinates = geolocation.getPosition();
      if (
        !containsCoordinate(
          map.getView().calculateExtent(map.getSize()),
          coordinates
        )
      ) {
        logger.warn("position outside map extent");
        setGeolocation({
          evt: "out",
          coordinates: coordinates,
          feature: positionFeature,
        });
      } else {
        setGeolocation({
          evt: "position",
          coordinates: coordinates,
          feature: positionFeature,
        });
        positionFeature.setGeometry(
          coordinates ? new Point(coordinates) : null
        );
      }
    });

    geolocation.on("change:accuracyGeometry", function () {
      logger.log("change:accuracyGeometry");
      //positionFeature.setGeometry(geolocation.getAccuracyGeometry());
    });
    geolocation.setTracking(true);
  };

  //*****************************************************************/
  //*******************           BUTTONS          ******************/
  //*****************************************************************/

  //*****************************************************************/
  //*******************          END BUTTONS       ******************/
  //*****************************************************************/

  return <React.Fragment></React.Fragment>;
};
export default Geolocalize;
