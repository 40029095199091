import { useContext, useEffect } from "react";
import { useDispatch } from "react-redux";
import Logger from "../richlogger";
import IframeCommunicator, {
  sendMessageToParent,
} from "../../Shared/iframe-communicator";
import Toc from "../../Shared/Toc";
import { setDevBugCookie } from "../cookies";
import { incrementZoom, decrementZoom } from "../../redux/actions/zoom";
import MapContext from "../../Contexts/MapContext.js";
import Layers from "../../Components/Layers";
import { HighLiteGeom } from "../Highlite";
import WFSFilters from "../../Shared/wfsfilters";
const logger = new Logger("Messages");
let _wmsLayers = [];
let _map = null;
let _extent = null;
let _activeLayer = null;
let _GiswaterFilters = [];
let _ProjectProperties = {};
let _ProjectLayers = {};
export const Messages = (props, vectorLayerRef, geoJSONLayerRef) => {
  const {
    wmsLayers,
    map,
    setTool,
    setToolAddpoint,
    setToolAddpolygon,
    setToolGeolocalize,
    extent,
    setDoInfo,
    activeLayer,
    setAactiveLayer,
    GiswaterFilters,
    setGiswaterFilters,
    ProjectProperties,
    setShowTiledBg,
    layers,
    setGeojsonObject,
    setCustomGeoms,
  } = useContext(MapContext);

  const dispatch = useDispatch();
  // on component mount
  useEffect(() => {
    const iframeCommunicator = new IframeCommunicator({
      sessionToken: props.iframeParams.sessionToken,
      domId: props.iframeParams.domId,
    });
    window.addEventListener("message", (evt) =>
      iframeCommunicator.onMessageReceived(evt, processMessage)
    );
  }, []);
  const processMessage = (event) => {
    logger.log("processMessage", event.data);
    switch (event.data.type) {
      case "setDebug":
        const debug = event.data.what ? Boolean(event.data.what) : false;
        setDevBugCookie({ debug: debug });
        break;
      case "zoomIn":
        dispatch(incrementZoom());
        break;
      case "zoomOut":
        dispatch(decrementZoom());
        break;
      case "loadWMSAvailableLayers":
        logger.log("loadWMSAvailableLayers", event.data);
        sendMessageToParent({
          type: "availableWMSLayers",
          layers: _wmsLayers,
        });
        break;
      case "toggleLayer":
        const properties = Toc.getLayerProperties(event.data.layer);
        const gutter =
          event.data.gutter !== null ? event.data.gutter : properties.gutter;
        const transparent =
          event.data.transparent !== null
            ? event.data.transparent
            : properties.layer_transparent;
        const singletile =
          event.data.singletile !== null
            ? event.data.singletile
            : properties.singletile;
        Layers.AddLayer(
          {
            name: event.data.layer,
            project_id: props.iframeParams.project_id,
            project_name: props.iframeParams.name,
            token: props.iframeParams.token,
            api_uri: props.iframeParams.api_uri,
            type: props.iframeParams.type,
            gutter: gutter,
            singletile: singletile,
            transparent: transparent,
          },
          _map
        );
        break;

      case "AddGeom":
        logger.log("AddGeom", event.data.geom);
        setTool(`add_${event.data.geom}`);
        if (event.data.geom === "Point") {
          setToolAddpoint(true);
        } else if (
          event.data.geom === "Polygon" ||
          event.data.geom === "Line"
        ) {
          setToolAddpolygon(true);
        }
        break;

      case "Geolocalize":
        logger.log("Geolocalize", event.data.toggle);
        setToolGeolocalize(event.data.toggle);
        break;
      case "clear":
        logger.log("clear");
        vectorLayerRef.current.clear();
        break;

      case "highlight":
        logger.log("highlight", event.data);
        HighLiteGeom(
          event.data.geom,
          event.data.zoom,
          vectorLayerRef,
          _map,
          true,
          dispatch
        );
        break;
      case "infoFromCoordinates":
        setDoInfo({
          info: event.data.info,
          layer: event.data.layer,
          hitTolerance: event.data.hitTolerance,
        });
        break;

      case "zoomToCoordinates":
        logger.log("zoomToCoordinates", event.data);
        HighLiteGeom(
          `POINT(${event.data.coordinates[0]} ${event.data.coordinates[1]})`,
          { type: "level", zoomLevel: event.data.zoomLevel },
          vectorLayerRef,
          _map,
          false,
          dispatch
        );
        break;
      case "zoomToExtent":
        logger.log("zoomToExtent");
        _map.getView().fit(_extent, _map.getSize());
        break;

      case "setActiveLayer":
        logger.log("setActiveLayer", event.data.layer);
        setAactiveLayer(event.data.layer);
        break;

      case "getActiveLayer":
        logger.log("getActiveLayer", _activeLayer);
        sendMessageToParent({
          type: "activeLayer",
          activeLayer: _activeLayer,
        });
        break;
      case "reloadDisplayedLayers":
        logger.log("reloadDisplayedLayers");
        Layers.reloadDisplayedLayers(_map, _GiswaterFilters);
        break;
      case "toggleGiswaterTiled":
        logger.log("toggleGiswaterTiled", _ProjectProperties);
        setShowTiledBg(event.data.toggle);
        sendMessageToParent({
          type: "giswaterTiledBackgroundDisplayed",
          visible: event.data.toggle,
        });
        break;
      case "setGiswaterFilters":
        logger.log("setGiswaterFilters", event.data);
        setGiswaterFilters(event.data.filters);
        break;
      case "getGiswaterLayerAvailableFilters":
        logger.log("getGiswaterLayerAvailableFilters", event.data);
        WFSFilters.setWFSFlters({
          name: event.data.name,
          project_id: props.iframeParams.project_id,
          project_name: props.iframeParams.name,
          token: props.iframeParams.token,
          api_uri: props.iframeParams.api_uri,
        })
          .then((msg) => {
            logger.success("getGiswaterLayerAvailableFilters", msg);
            sendMessageToParent({
              type: "GiswaterLayerAvailableFilters",
              filters: msg,
            });
          })
          .catch((e) => {
            logger.error("getGiswaterLayerAvailableFilters", e);
          });
        break;

      case "addGeoJSON":
        logger.log("addGeoJSON", event.data);
        if (typeof event.data.geoJSON != "object") {
          sendMessageToParent({
            type: "error",
            error: "invalid geoJSON",
          });
          return;
        }
        setGeojsonObject(true);
        geoJSONLayerRef.current.addGeoJson(
          event.data.geoJSON,
          event.data.options,
          event.data.name
        );
        break;

      case "clearGeoJSON":
        logger.log("clearGeoJSON", event.data);
        geoJSONLayerRef.current.clear();
        setGeojsonObject(false);
        break;
      case "removeGeoJSONLayer":
        logger.log("removeGeoJSONLayer", event.data);
        if (!event.data.name) return;
        geoJSONLayerRef.current.remove(event.data.name);
        break;
      case "setCustomColors":
        logger.log("setCustomColors", event.data);
        setCustomGeoms(event.data.properties);
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    _wmsLayers = wmsLayers;
  }, [wmsLayers]);
  useEffect(() => {
    _ProjectProperties = ProjectProperties;
  }, [ProjectProperties]);
  useEffect(() => {
    _GiswaterFilters = GiswaterFilters;
  }, [GiswaterFilters]);
  useEffect(() => {
    _activeLayer = activeLayer;
  }, [activeLayer]);
  useEffect(() => {
    _extent = extent;
  }, [extent]);

  useEffect(() => {
    _map = map;
  }, [map]);
  useEffect(() => {
    _ProjectLayers = layers;
    // setLayers(layers);
  }, [layers]);
};
