import WKTFormatter from "./wktformatter";
import { sendMessageToParent } from "./iframe-communicator";
import { setZoom } from "../redux/actions/zoom";
import { getCenter } from "ol/extent";
export const HighLiteGeom = (
  geom,
  zoom,
  vectorLayerRef,
  mapObject,
  draw,
  dispatch
) => {
  console.log(geom);
  var rawGeometry = WKTFormatter.StringToGeom(geom);
  if (rawGeometry) {
    //add element
    if (draw)
      vectorLayerRef.current.addFeature(rawGeometry, "highlight", false);
    //center map on element
    mapObject.getView().setCenter(getCenter(rawGeometry.getExtent()));
    //zoom to element
    if (zoom.type === "level") {
      let zoomLevel = parseInt(zoom.zoomLevel);
      if (!isNaN(zoomLevel)) {
        if (zoomLevel < 21 && zoomLevel > 1) {
          dispatch(setZoom(zoomLevel));
        }
      }
    } else {
      mapObject.getView().fit(rawGeometry.getExtent(), mapObject.getSize());
    }
  } else {
    sendMessageToParent({
      type: "error",
      error: "Invalid geometry",
    });
  }
};
