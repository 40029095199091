import Feature from "ol/Feature";

import { Fill, Stroke, Circle, Style, RegularShape } from "ol/style";

const createFeature = (geom, id, customGeoms) => {
  const {
    geom_fill_color,
    geom_stroke_color,
    geom_shape,
    geom_radius,
    geom_stroke_width,
  } = customGeoms;
  console.log("createFeature", geom_fill_color, geom_stroke_color, geom_shape);
  const stroke = new Stroke({
    color: geom_stroke_color,
    width: geom_stroke_width,
  });
  const fill = new Fill({
    color: geom_fill_color,
  });
  let pointShape = new Circle({
    radius: geom_radius,
    fill: new Fill({
      color: geom_fill_color,
    }),
    stroke: new Stroke({
      color: geom_stroke_color,
      width: geom_stroke_width,
    }),
  });
  if (geom_shape !== "circle") {
    pointShape = new RegularShape({
      fill: fill,
      stroke: stroke,
      points: 4,
      radius: geom_radius,
      angle: Math.PI / 4,
    });
  }

  const feuStyle = new Style({
    fill: fill,
    stroke: stroke,
    image: pointShape,
  });

  const newFeature = new Feature({
    geometry: geom,
  });

  newFeature.setStyle(feuStyle);
  newFeature.setId(id);
  return newFeature;
};

const Features = {
  createFeature,
};
export default Features;
