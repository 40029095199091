import {
  useContext,
  useEffect,
  forwardRef,
  useImperativeHandle,
  useState,
} from "react";
import MapContext from "../../../Contexts/MapContext.js";
import GeoJSON from "ol/format/GeoJSON";
import OLVectorLayer from "ol/layer/Vector";
import { Circle as CircleStyle, Fill, Stroke, Style } from "ol/style";
import RichLogger from "../../../Shared/richlogger";
import { sendMessageToParent } from "../../../Shared/iframe-communicator";
import { vector } from "../../Source";
let strokeColor = "#ff0000";
let fillColor = "#ff0000";
const logger = new RichLogger("components/GeoJSONLayer");
var styles = null;
var geoJsonLayers = [];
var styleFunction = function (feature) {
  return styles[feature.getGeometry().getType()];
};

let geojsonlayer = null;
const GeoJSONLayer = forwardRef((props, ref) => {
  const { map } = useContext(MapContext);
  const { source, zIndex, projectProperties, customGeoms } = props;
  const [render, setRender] = useState(false);
  strokeColor = customGeoms.geom_stroke_color;
  fillColor = customGeoms.geom_fill_color;
  // const [geoJsonLayers, setGeoJsonLayers] = useState([]);
  const remove = (name) => {
    logger.info("remove", name);
    map.getLayers().forEach((layer) => {
      if (layer.get("name") && layer.get("name") === name) {
        map.removeLayer(layer);
        geoJsonLayers.splice(geoJsonLayers.indexOf(name), 1);
        sendMessageToParent({
          type: "geoJSONlayers",
          layers: geoJsonLayers,
        });
      }
    });
  };

  const clear = () => {
    logger.info("clear", geoJsonLayers);
    geoJsonLayers.forEach((geojson) => {
      map.getLayers().forEach((layer) => {
        if (layer.get("name") && layer.get("name") === geojson) {
          layer.getSource().clear();
        }
      });
    });
    geoJsonLayers = [];
  };

  const addGeoJson = (geoJSONdata, options, name) => {
    logger.info("addGeoJson", geoJSONdata);
    setGeoJSONStyle(options);
    let geojsonlayer = new OLVectorLayer({
      updateWhileInteracting: true,
      source: new vector({
        features: new GeoJSON().readFeatures(geoJSONdata),
        useSpatialIndex: true,
      }),
      style: styleFunction,
      name: name,
    });
    geoJsonLayers.push(name);
    map.addLayer(geojsonlayer);
    geojsonlayer.setZIndex(zIndex);
    sendMessageToParent({
      type: "geoJSONlayers",
      layers: geoJsonLayers,
    });
  };

  const setGeoJSONStyle = (options) => {
    logger.info("setGeoJSONStyle", options);
    strokeColor = options.strokecolor ? options.strokecolor : strokeColor;
    fillColor = options.fillcolor ? options.fillcolor : fillColor;
    var image = new CircleStyle({
      radius: 5,
      fill: null,
      stroke: new Stroke({ color: strokeColor, width: 1 }),
    });

    styles = {
      Point: new Style({
        image: image,
      }),
      LineString: new Style({
        stroke: new Stroke({
          color: strokeColor,
          width: 1,
        }),
      }),
      MultiLineString: new Style({
        stroke: new Stroke({
          color: strokeColor,
          width: 1,
        }),
      }),
      MultiPoint: new Style({
        image: image,
      }),
      MultiPolygon: new Style({
        stroke: new Stroke({
          color: strokeColor,
          width: 1,
        }),
        fill: new Fill({
          color: fillColor,
        }),
      }),
      Polygon: new Style({
        stroke: new Stroke({
          color: strokeColor,
          lineDash: [4],
          width: 3,
        }),
        fill: new Fill({
          color: fillColor,
        }),
      }),
      GeometryCollection: new Style({
        stroke: new Stroke({
          color: strokeColor,
          width: 2,
        }),
        fill: new Fill({
          color: strokeColor,
        }),
        image: new CircleStyle({
          radius: 10,
          fill: null,
          stroke: new Stroke({
            color: strokeColor,
          }),
        }),
      }),
      Circle: new Style({
        stroke: new Stroke({
          color: strokeColor,
          width: 2,
        }),
        fill: new Fill({
          color: fillColor,
        }),
      }),
    };
  };
  useImperativeHandle(ref, () => ({
    remove: remove,
    clear: clear,
    addGeoJson: addGeoJson,
  }));

  useEffect(() => {
    if (!map) return;
  }, [map]);
  return null;
});
export default GeoJSONLayer;
