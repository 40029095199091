import { createContext, useState } from "react";
import { useSelector } from "react-redux";
const MapContext = createContext(true);
const MapProvider = (props) => {
  const [map, setMap] = useState(null);
  //const [zoom] = useState(3);
  const [srid, setSrid] = useState(null);
  const [extent, setExtent] = useState(null);
  const [layers, setLayers] = useState([]);
  const [addedGeom, setAddedGeom] = useState(false);
  const [debug, setDebug] = useState(false);
  const [tool, setTool] = useState(null);
  const [geolocation, setGeolocation] = useState(null);
  const [wmsLayers, setWmsLayers] = useState([]);
  const [ToolAddpoint, setToolAddpoint] = useState(false);
  const [ToolAddpolygon, setToolAddpolygon] = useState(false);
  const [ToolGeolocalize, setToolGeolocalize] = useState(false);
  const [activeLayer, setAactiveLayer] = useState(null);
  const [doInfo, setDoInfo] = useState(null);
  const [clickedCoordinates, setClickedCoordinates] = useState(null);
  const [GiswaterFilters, setGiswaterFilters] = useState([]);
  const [ProjectProperties, setProjectProperties] = useState({});
  const [customGeoms, setCustomGeoms] = useState({
    geom_stroke_color: "rgb(20, 255, 0,1)",
    geom_fill_color: "rgb(19, 39, 99,0.5)",
    geom_stroke_width: 1,
    geom_shape: "circle",
    geom_radius: 4,
  });
  const [showTiledBg, setShowTiledBg] = useState(false);
  const [geojsonObject, setGeojsonObject] = useState(null);
  //Redux
  const zoom = useSelector((state) => state.zoom.level);

  return (
    <MapContext.Provider
      value={{
        map,
        setMap,
        zoom,
        wmsLayers,
        setWmsLayers,
        srid,
        setSrid,
        addedGeom,
        setAddedGeom,
        debug,
        setDebug,
        layers,
        setLayers,
        extent,
        setExtent,
        tool,
        setTool,
        geolocation,
        setGeolocation,
        ToolAddpoint,
        setToolAddpoint,
        ToolAddpolygon,
        setToolAddpolygon,
        ToolGeolocalize,
        setToolGeolocalize,
        activeLayer,
        setAactiveLayer,
        clickedCoordinates,
        setClickedCoordinates,
        doInfo,
        setDoInfo,
        GiswaterFilters,
        setGiswaterFilters,
        ProjectProperties,
        setProjectProperties,
        showTiledBg,
        setShowTiledBg,
        geojsonObject,
        setGeojsonObject,
        customGeoms,
        setCustomGeoms,
      }}
    >
      {props.children}
    </MapContext.Provider>
  );
};
// const SearchConsumer = SearchContext.Consumer;
// export { SearchProvider, SearchConsumer };
export { MapProvider };
export default MapContext;
