import { INCREMENT_ZOOM, DECREMENT_ZOOM, SET_ZOOM } from "../actionTypes";

export const incrementZoom = () => {
  return {
    type: INCREMENT_ZOOM,
    payload: {},
  };
};

export const decrementZoom = () => {
  return {
    type: DECREMENT_ZOOM,
    payload: {},
  };
};

export const setZoom = (level) => {
  return {
    type: SET_ZOOM,
    payload: { level },
  };
};
